import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { Header } from 'oialbert-ui';
import { memo } from 'react';
import { useHistory } from 'react-router';
import Intercom from '@intercom/messenger-js-sdk';
import { Navbar } from '../Navbar';
import useAuth from '../../hooks/useAuth';

type LayoutBaseProps = {
  title: string;
  header?: React.ReactNode;
  children: React.ReactNode;
};

export const LayoutBase = memo(
  ({ title, header, children }: LayoutBaseProps) => {
    const history = useHistory();
    const { user, company } = useAuth();

    Intercom({
      app_id: 'm95sx3bt',
      user_id: user?.id,
      name: company?.name ?? '',
      email: user?.email,
      horizontal_padding: 40,
      vertical_padding: 20,
    });

    return (
      <IonPage>
        <IonHeader className="sm:hidden ion-no-border bg-white shadow-sm">
          {!header && (
            <section className="mt-10 px-5 pb-5">
              <Header onClick={() => history.goBack()} title={title} />
            </section>
          )}
          {header}
        </IonHeader>
        <IonContent>
          <section className="sm:flex p-3 min-h-screen bg-gray-50">
            <section className="sm:w-2/12 mr-5">
              <Navbar />
            </section>

            <section className="sm:w-10/12">{children}</section>
          </section>
        </IonContent>
      </IonPage>
    );
  }
);
